import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const PublicationsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Publications" />
      <div className="publication-post-container">
        <div className="publication-post-feed public">
          <article className="publication-post-card false post with-image pub1">
            <a
              className="publication-post-card-link"
              target="_blank"
              rel="noreferrer"
              href="https://nakid.online/2020/01/22/berlin-a-new-visual-story-by-claudia-du-lievre-exclusive-editorial/"
            >
              <div className="publication-post-card-content">
                <h2 className="publication-post-card-title">Berlin</h2>
              </div>
            </a>
          </article>
          <article className="publication-post-card false post with-image pub2">
            <a
              className="publication-post-card-link"
              target="_blank"
              rel="noreferrer"
              href="/OTR16D_NO CONTEST.pdf"
            >
              <div className="publication-post-card-content">
                <h2 className="publication-post-card-title">OTR Issue #16</h2>
              </div>
            </a>
          </article>
        </div>
      </div>

      <div className="publication-post-container">
        <div className="publication-post-feed">
          <article className="publication-post-card-fiximage publication-post-card false post with-image pub3">
            <a
              className="publication-post-card-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.c-heads.com/2020/05/07/orange-bliss/"
            >
              <div className="publication-post-card-content">
                <h2 className="publication-post-card-title">Orange Bliss</h2>
              </div>
            </a>
          </article>
          <article className="publication-post-card false post with-image pub4">
            <a
              className="publication-post-card-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.cake-mag.com/natalia/"
            >
              <div className="publication-post-card-content">
                <h2 className="publication-post-card-title">Natalia</h2>
              </div>
            </a>
          </article>
        </div>
      </div>

      <div className="publication-post-container">
        <div className="publication-post-feed">
          <article className="publication-post-card false post with-image pub5">
            <a
              className="publication-post-card-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.c-heads.com/2019/02/19/5-minutes-marina/"
            >
              <div className="publication-post-card-content">
                <h2 className="publication-post-card-title">Marina</h2>
              </div>
            </a>
          </article>
          <article className="publication-post-card false post with-image pub6">
            <a
              className="publication-post-card-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.weareyugen.com/pages/editorial"
            >
              <div className="publication-post-card-content">
                <h2 className="publication-post-card-title">Peony Blue</h2>
              </div>
            </a>
          </article>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <PublicationsPage location={props.location} data={data} {...props} />
    )}
  />
)
